import { useState, useEffect } from "react";

type Time = {
  hours: string;
  minutes: string;
};

const getTime = (timeZone: string): Time => {
  const now = new Date(new Date().toLocaleString("en-US", { timeZone }));
  const hours = padWithZero(now.getHours());
  const minutes = padWithZero(now.getMinutes());
  return { hours, minutes };
};

const padWithZero = (number: number): string =>
  number.toString().padStart(2, "0");

export function WorldTimes() {
  const [lonTime, setLonTime] = useState<Time>(() => getTime("Europe/London"));
  const [nycTime, setNycTime] = useState<Time>(() =>
    getTime("America/New_York")
  );

  useEffect(() => {
    const timerId = setInterval(() => {
      setLonTime(getTime("Europe/London"));
      setNycTime(getTime("America/New_York"));
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const colon = <span className="animate-pulse">:</span>;

  return (
    <div className="flex flex-col gap-0 font-mono text-xs tracking-widest md:flex-row md:gap-8">
      <div>
        {lonTime.hours}
        {colon}
        {lonTime.minutes} LON
      </div>
      <div>
        {nycTime.hours}
        {colon}
        {nycTime.minutes} NYC
      </div>
    </div>
  );
}
